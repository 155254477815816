<template>
  <div id="dashboard">
    <v-container fluid>
      <v-row dense>
        <v-col :cols="8">
          <v-card>
            <v-card-subtitle class="text-center"
              ><h2>ADOLFO BETANCOURT ALCÁNTARA</h2></v-card-subtitle
            >
            <v-card-subtitle class="text-center mt-n8"
              >Director General / Socio Fundador</v-card-subtitle
            >
            <v-row dense class="mt-4">
              <v-col :cols="12">
                <v-card>
                  <v-card-title class="blue--text"
                    >Información Académica</v-card-title
                  >
                  <v-card-text>
                    <div>Ing. Electrónica y Computación(UAM)</div>
                    <div>
                      <strong>Certificaciones</strong>
                      PMI AMID I, II
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col :cols="12">
                <v-card>
                  <v-card-title class="blue--text"
                    >Información Comercial</v-card-title
                  >
                  <v-card-text>
                    <div>
                      <strong>Nombre del Negocio: </strong>Abadía Consulting
                      S.A. de C.V.
                    </div>
                    <div>
                      <strong>Dirección comercial: </strong>Torres Adalid 1006,
                      Ciudad de México
                    </div>
                    <div><strong>Años en el negocio: </strong>4 años</div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col :cols="12">
                <v-card>
                  <v-card-title class="blue--text"
                    >Vida Profesional</v-card-title
                  >
                  <v-card-text>
                    <div>
                      <ul>
                        <li>
                          27 años de carrera en el sector Financiero Mexicano,
                          los últimos 14 como director de TI.
                        </li>
                        <li>
                          Director de Operaciones con un partner de Microsoft.
                        </li>
                      </ul>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <div class="text-end">
              <v-btn
                rounded
                color="primary white--text"
                class="mx-5 my-2"
                to="/personal"
                >Siguiente</v-btn
              >
            </div>
          </v-card>
        </v-col>
        <v-col :cols="4" class="align-center">
          <v-card>
            <v-img
              :src="'images/fotos/fotoPersonal.png'"
              class="white--text align-end"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.2)"
            >
              <v-card-subtitle class="white--text"
                ><h5>Primavera 2020</h5></v-card-subtitle
              >
            </v-img>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import pageFooter from "@/components/Footer.vue";
import header from "@/components/header.vue";
export default {
  components: {
    pageFooter,
  },
  data() {
    return {
      dialog: false,
      headers: [
        {
          text: "N°",
          align: "center",
          value: "id",
        },
        {
          text: "Nombre de Puesto",
          align: "center",
          value: "nombrePuesto",
        },
        {
          text: "Editar  /   Ver  / Eliminar",
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
      tab: null,
      text: "inin",
      align: ["start", "end", "center", "baseline", "stretch"],
    };
  },
  methods: {},
  created() {},
};
</script>
